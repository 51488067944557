import React, { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Loadable from "../layouts/full/shared/loadable/Loadable";
import Billing from "src/views/collegeBilling/Billing";

const FullLayout = Loadable(lazy(() => import("../layouts/full/FullLayout")));

// const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')));
const Banner = Loadable(lazy(() => import("../views/banner/Banner")));
const Service = Loadable(lazy(() => import("../views/service/Service")));
const Testimonial = Loadable(
  lazy(() => import("../views/testimonial/Testimonial"))
);
const OrderList = Loadable(lazy(() => import("../views/order/OrderList")));

const Error = Loadable(lazy(() => import("../views/authentication/Error")));
const Login = Loadable(lazy(() => import("../views/authentication/Login")));

// master imports
const CreateProduct = Loadable(
  lazy(() => import("src/views/master/CreateProduct"))
);
const Hostel = Loadable(lazy(() => import("src/views/master/Hostel")));
const BagMaster = Loadable(lazy(() => import("src/views/master/BagMaster")));
const RoomMaster = Loadable(lazy(() => import("src/views/master/RoomMaster")));
const CategoryMaster = Loadable(
  lazy(() => import("src/views/master/CategoryMaster"))
);
const CollegePrice = Loadable(
  lazy(() => import("src/views/master/CollegePrice"))
);
const Staff = Loadable(lazy(() => import("src/views/master/Staff")));
const StudentMaster = Loadable(
  lazy(() => import("src/views/master/StudentMaster"))
);

// reports
const StudentOutstanding = Loadable(
  lazy(() => import("src/views/reports/StudentOutstanding"))
);
const BagTab = Loadable(lazy(() => import("src/views/reports/BagTab")));
// const BagReport = Loadable(lazy(() => import("src/views/reports/BagReport")));
const BillSummary = Loadable(
  lazy(() => import("src/views/reports/BillSummary"))
);

function Routes() {
  return useRoutes([
    {
      path: "/",
      element: <FullLayout />,
      children: [
        { path: "/", element: <Navigate to="/orders" /> },
        // { path: 'dashboard', element: <Dashboard /> },
        { path: "banner", element: <Banner /> },
        { path: "service", element: <Service /> },
        { path: "testimonial", element: <Testimonial /> },
        { path: "orders", element: <OrderList /> },
        { path: "college_bill", element: <Billing /> },

        // masters
        { path: "master/product", element: <CreateProduct /> },
        { path: "master/hostel", element: <Hostel /> },
        { path: "master/bag", element: <BagMaster /> },
        { path: "master/room", element: <RoomMaster /> },
        { path: "master/category", element: <CategoryMaster /> },
        { path: "master/college", element: <CollegePrice /> },
        { path: "master/staff", element: <Staff /> },
        { path: "master/student", element: <StudentMaster /> },

        // reports
        { path: "report/student-outstanding", element: <StudentOutstanding /> },
        { path: "report/bag-report", element: <BagTab /> },
        { path: "report/bill-summary", element: <BillSummary /> },
      ],
    },
    { path: "404", element: <Error /> },
    { path: "auth/login", element: <Login /> },
    { path: "*", element: <Navigate to="/404" /> },
  ]);
}

export default Routes;
