import * as React from "react";
import PropTypes from "prop-types";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function Loader({ start }) {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={start}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
Loader.propTypes = {
  start: PropTypes.bool.isRequired,
};

export default Loader;
