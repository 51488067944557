import React, { Suspense } from "react";
import { Box, Skeleton } from "@mui/material";

// eslint-disable-next-line react/display-name
const Loadable = (Component) => (props) => {
  const skeletons = [
    { width: "60%", height: 30, mb: 2 },
    { width: "90%", height: 20, mb: 1 },
    { width: "80%", height: 20, mb: 1 },
    { width: "70%", height: 20, mb: 1 },
    { width: "85%", height: 20, mb: 1 },
    { width: "100%", height: 200, mb: 2 },
    { width: "95%", height: 20, mb: 1 },
    { width: "90%", height: 20, mb: 1 },
    { width: "85%", height: 20, mb: 1 },
    { width: "80%", height: 20, mb: 1 },
    { width: "75%", height: 20, mb: 1 },
  ];

  return (
    <Suspense
      fallback={
        <Box sx={{ width: "100%" }}>
          {skeletons.map((skeleton, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width={skeleton.width}
              height={skeleton.height}
              sx={{ mb: skeleton.mb }}
            />
          ))}
        </Box>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default Loadable;
