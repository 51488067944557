import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import RoutesTwo from "./routes/Routes";
import { useLocation } from "react-router";

import { SnackBarProvider } from "./context/SnackBarContext";

import { baselightTheme } from "./theme/DefaultColors";

import "./App.css";

function App() {
  const theme = baselightTheme;
  const location = useLocation();

  // Get the current URL
  const params = location.search;

  let ur_d = params?.slice(8);
  if (ur_d) {
    localStorage.setItem("lgpath", ur_d);
  }

  return (
    <Box sx={{ background: "#edeff6" }}>
      <SnackBarProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RoutesTwo />
        </ThemeProvider>
      </SnackBarProvider>
    </Box>
  );
}

export default App;
