import axios from "axios";
import { baseUrl, redirectUrl } from "../../config";

export const fetchData = async (endpoint) => {
  try {
    const response = await axios.get(`${baseUrl}${endpoint}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      window.location.href = redirectUrl;
    } else {
      console.error("An error occurred:", error);
    }
  }
};

export const postData = async (endpoint, data, formType = "json") => {
  try {
    const headers = {
      "Content-Type":
        formType === "form" ? "multipart/form-data" : "application/json",
    };

    const response = await axios.post(`${baseUrl}${endpoint}`, data, {
      withCredentials: true,
      headers,
    });

    return response.data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      window.location.href = redirectUrl;
    }
    // Re-throw the error so it can be caught by react-query
    throw new Error(
      error.response?.data?.message ||
        error.message ||
        "An error occurred while posting data."
    );
  }
};

export const deleteData = async (endpoint) => {
  try {
    const response = await axios.delete(`${baseUrl}${endpoint}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      window.location.href = redirectUrl;
    }
    // Re-throw the error so it can be caught by react-query
    throw new Error(
      error.response?.data?.message ||
        error.message ||
        "An error occurred while deleting data."
    );
  }
};

export const putData = async (endpoint, data, formType = "json") => {
  try {
    const headers = {
      "Content-Type":
        formType === "form" ? "multipart/form-data" : "application/json",
    };

    const response = await axios.put(`${baseUrl}${endpoint}`, data, {
      withCredentials: true,
      headers,
    });

    return response.data;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      window.location.href = redirectUrl;
    }
    // Re-throw the error so it can be caught by react-query
    throw new Error(
      error.response?.data?.message ||
        error.message ||
        "An error occurred while updating data."
    );
  }
};
