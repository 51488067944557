import PropTypes from "prop-types";
import React, { forwardRef } from "react";

import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const Transition = forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

// Add display name for the Transition component
Transition.displayName = "Transition";

function AlertDialog({
  open = false, // Set default value to false
  onClose,
  title,
  message,
  onConfirm,
  onCancel,
  buttonType,
  buttonText,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      fullWidth
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color={buttonType}
          autoFocus
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  message: PropTypes.any,
  buttonType: PropTypes.string,
  buttonText: PropTypes.string,
  title: PropTypes.string,
};

export default AlertDialog;
