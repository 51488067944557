import React, { useEffect, useState } from "react";

import { TextField, IconButton, Stack } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

import PageContainer from "src/components/container/PageContainer";
import DataTable from "src/components/DataTable";
import DashboardCard from "src/components/shared/DashboardCard";
import Loader from "src/components/Loader";
import AlertDialog from "src/components/AlertDialog";

import { deleteData, postData } from "../utilities/api";
import { redirectUrl } from "src/config";
import { useSnackBar } from "src/context/SnackBarContext";
import dayjs from "dayjs";

const columns = [
  { field: "reg_no", headerName: "Reg No", width: 90 },

  {
    field: "hostel",
    headerName: "Hostel",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "date",
    headerName: "Date & time",
    flex: 1,
    minWidth: 150,
    renderCell: (params) => {
      const formattedDate = dayjs(params.date).format("DD/MM/YYYY - h:mm A");
      return <span>{formattedDate}</span>;
    },
  },
  {
    field: "bag",
    headerName: "Bag",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "qty",
    headerName: "Qty",
    flex: 1,
    minWidth: 150,
  },
];

function Billing() {
  const { showSnackbar } = useSnackBar();
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    onConfirm: null,
    onCancel: null,
    title: "",
    message: "",
    buttonType: "",
    buttonText: "",
  });
  const [qrData, setQrData] = useState(""); // Manage QR data state

  useEffect(() => {
    getOrderData();
  }, []);
  const getOrderData = async () => {
    setLoading(true);
    const getOrder = await postData("/api/orderReportByDate", {
      fromDate: new Date(),
    });
    if (getOrder?.success) {
      setTable(getOrder?.data);
    }
    setLoading(false);
  };

  const handleOrder = async (data) => {
    setLoading(true);
    try {
      const addData = await postData("/api/orderAddQr", {
        data: data,
        date: new Date().toLocaleString("sv-SE"),
      });

      getOrderData();
      if (addData.success) {
        showSnackbar("success", addData.message);
        setQrData("");
      } else {
        showSnackbar("error", addData.message);
      }
    } catch (error) {
      showSnackbar("error", "An error occurred while processing the order");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && qrData) {
      handleOrder(qrData);
    }
  };

  const cancelPrint = () => {
    setAlert({
      open: false,
    });
  };

  const handleDelete = async (params) => {
    setLoading(true);
    try {
      const response = await deleteData(
        `/api/orderDeleteById?oid=${params.oid}`
      );
      if (response.success) {
        showSnackbar("error", response.message);
        getOrderData();
        cancelPrint();
      } else {
        showSnackbar("warning", response.message);
      }
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        window.location.href = redirectUrl;
      }
    } finally {
      setLoading(false);
    }
  };
  const handleCode = (e) => {
    const { value } = e.target;
    // Remove both double quotes and single quotes
    const sanitizedValue = value.replace(/['"]/g, "");
    console.log(sanitizedValue);
    setQrData(sanitizedValue);
  };

  const renderButtons = (params) => (
    <Stack direction="row" gap={2}>
      <IconButton
        aria-label="delete"
        color="error"
        size="small"
        onClick={() =>
          openAlert({
            open: true,
            onConfirm: () => handleDelete(params),
            onCancel: cancelPrint,
            title: "Delete",
            message: "Do you want to delete this student?",
            buttonType: "error",
            buttonText: "Delete",
            row: params,
          })
        }
      >
        <DeleteIcon />
      </IconButton>
    </Stack>
  );

  const columnsWithButtons = [
    ...columns,
    {
      field: "actions",
      headerName: "",
      renderCell: renderButtons,
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 150,
    },
  ];
  const openAlert = ({
    open,
    onConfirm,
    onCancel,
    title,
    message,
    buttonType,
    buttonText,
    row,
  }) => {
    setAlert({
      open,
      onConfirm,
      onCancel,
      title,
      message,
      buttonType,
      buttonText,
    });
  };
  return (
    <>
      <AlertDialog
        open={alert.open}
        onConfirm={alert.onConfirm}
        onCancel={alert.onCancel}
        title={alert.title}
        message={alert.message}
        buttonType={alert.buttonType}
        buttonText={alert.buttonText}
      />
      <Loader start={loading} />
      <PageContainer>
        <DashboardCard title="College Bill">
          <TextField
            autoFocus
            label="QR code scanner"
            placeholder="use here to scan QR"
            value={qrData}
            onChange={handleCode}
            // onChange={(e) => setQrData(e.target.value)} // Update QR data state
            onKeyDown={handleKeyDown} // Handle Enter key press
          />
          <DataTable columns={columnsWithButtons} rows={table} />
        </DashboardCard>
      </PageContainer>
    </>
  );
}

export default Billing;
